<template>
  <!-- 合同物流 - 创建计划  -->
  <div class="createPlanPage">
    <div class="facilityBox" v-loading="flag.loading">
      <!-- tab切换  -->
      <el-tabs v-model="flag.activeTab" @tab-click="changeTabs">
        <el-tab-pane label="委托服务商承运" name="1"></el-tab-pane>
        <el-tab-pane label="自有运力承运" name="0"></el-tab-pane>
        <el-tab-pane label="司机抢单" name="2"></el-tab-pane>
      </el-tabs>

      <!-- 温馨提示 -->
      <div class="tips" @mouseover="flag.tipsTxtShow = true" @mouseout="flag.tipsTxtShow = false">
        <i class="el-icon-question"></i>
        <span>帮助</span>

        <div class="tipsTxt" v-show="flag.tipsTxtShow">
          <p>
            1.直接找司机运货，则选择自营。委托物流服务公司运货，则选择服务商
          </p>
          <p>
            2.必填项中，请输入货品真实信息，包括支付给司机或物流服务公司的运费
          </p>
          <div class="triangle"></div>
        </div>
      </div>

      <div>
        <!-- 委托承运Form  inline   label-width="200px"-->
        <el-form ref="supplierForm" :model="supplierForm" :rules="supplierRule" label-position="top" :key="1">
          <!-- 服务商信息 -->
          <div class="formItem" v-if="flag.activeTab == 1">
            <div class="formItem-title">
              <span></span>
              <p>服务商信息</p>
            </div>
            <el-form-item label="选择服务商" prop="FacilitatorID" v-if="flag.activeTab == 1">
              <el-select v-model="supplierForm.FacilitatorID" clearable placeholder="请选择服务商" class="inputWidth">
                <el-option v-for="item in supplierList" :key="item.id" :label="item.Status != '100'
                  ? item.EnterpriseFullName + ': 该企业平台审核中'
                  : item.EnterpriseFullName
                  " :value="item.UserID" :disabled="item.Status != '100' ? true : false">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!-- 货运信息 -->
          <div class="formItem">
            <el-divider v-if="flag.activeTab == 1" content-position="left"></el-divider>
            <div class="formItem-title">
              <span></span>
              <p>货运信息</p>
            </div>
            <!-- 起始地, 装货地 -->
            <el-form-item label="起始地" prop="StartPosition.area">
              <el-cascader ref="StartPosition" style="width: 202px" v-model="supplierForm.StartPosition.area"
                :props="supplierForm.StartPosition" :disabled="false" clearable placeholder="请选择起始地"
                class="inputWidth"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="StartAdressRoad">
              <el-input v-model="supplierForm.StartAdressRoad" placeholder="请输入起始地街道、门牌号具体地址" class="inputWidth"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>
            <el-button class="formItem-btn" type="primary" icon="el-icon-right" circle
              @click="unifyAddress(1)"></el-button>
            <el-form-item label="提货地点" prop="UpAddressPosition.area">
              <el-cascader ref="UpAddressPosition" style="width: 202px" v-model="supplierForm.UpAddressPosition.area"
                :props="supplierForm.UpAddressPosition" :disabled="false" clearable
                v-if="supplierForm.UpAddressPosition.isShow" placeholder="请选择提货地点" class="inputWidth"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="UpAdressRoad">
              <el-input v-model="supplierForm.UpAdressRoad" placeholder="请输入提货地点街道、门牌号具体地址" class="inputWidth"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>

            <!-- 到达地, 卸货地 -->
            <el-form-item label="到达地" prop="EndPosition.area">
              <el-cascader ref="EndPosition" style="width: 202px" v-model="supplierForm.EndPosition.area"
                :props="supplierForm.EndPosition" :disabled="false" clearable placeholder="请选择到达地"
                class="inputWidth"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="EndAddressRoad">
              <el-input v-model="supplierForm.EndAddressRoad" placeholder="请输入到达地街道、门牌号具体地址" class="inputWidth"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>
            <el-button class="formItem-btn" type="primary" icon="el-icon-right" circle
              @click="unifyAddress(2)"></el-button>
            <el-form-item label="卸货地点" prop="UnAddressPosition.area">
              <el-cascader ref="UnAddressPosition" style="width: 202px" v-model="supplierForm.UnAddressPosition.area"
                :props="supplierForm.UnAddressPosition" :disabled="false" clearable
                v-if="supplierForm.UnAddressPosition.isShow" placeholder="请选择卸货地点" class="inputWidth"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="UnAdressRoad">
              <el-input v-model="supplierForm.UnAdressRoad" placeholder="请输入卸货地点街道、门牌号具体地址" class="inputWidth"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>

            <el-form-item label="装卸时间" prop="LoadingTime">
              <el-date-picker v-model="supplierForm.LoadingTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至" start-placeholder="起始时间" end-placeholder="结束时间"
                @change="loadingTime"></el-date-picker>
            </el-form-item>
            <el-form-item label="运输时间" prop="SendTime">
              <el-date-picker v-model="supplierForm.SendTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至" start-placeholder="起始时间" end-placeholder="到达时间" @change="sendTime"></el-date-picker>
            </el-form-item>
          </div>

          <!-- 货物信息 -->
          <div class="formItem">
            <el-divider content-position="left"></el-divider>
            <div class="formItem-title">
              <span></span>
              <p>货物信息</p>
            </div>
            <el-form-item label="货品名称" prop="GoodsName">
              <el-input v-model="supplierForm.GoodsName" placeholder="请输入货品名称" class="inputWidth"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="货品单位" prop="GoodsUnit">
              <el-select v-model="supplierForm.GoodsUnit" clearable placeholder="请选择货品单位" class="inputWidth">
                <el-option v-for="item in goodsUnitList" :key="item.Code" :label="item.Name" :value="item.Name">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货品数量" prop="LoadCount">
              <el-input type="text" v-model="supplierForm.LoadCount"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" placeholder="请输入货品总数量"
                class="inputWidth" maxlength="8"></el-input>
            </el-form-item>
            <el-form-item label="货品总重" prop="GoodsTotalWeight">
              <el-input type="text" v-model="supplierForm.GoodsTotalWeight"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" placeholder="请输入货品总重量"
                class="inputWidth" maxlength="8">
                <template slot="append">吨</template></el-input>
            </el-form-item>
            <el-form-item label="货品价值" prop="GoodsValue">
              <el-input type="text" v-model="supplierForm.GoodsValue"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" placeholder="请输入货品总价值"
                class="inputWidth" maxlength="8">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="flag.activeTab == 2
              ? '货品单价（例如：运输1吨，单价为100元）'
              : '运费'
              " prop="CarrierPrice" :rules="{
    required: true,
    message: `请输入${flag.activeTab == 2 ? '运输数量所对应的运输单价' : '运费'
      }`,
    trigger: 'change',
  }">
              <el-input type="text" v-model="supplierForm.CarrierPrice"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" :placeholder="`请输入${flag.activeTab == 2 ? '运输数量所对应的运输单价' : '运费'
                  }`" maxlength="8" class="inputWidth">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </div>

          <!-- 人员信息 -->
          <div class="formItem">
            <el-divider content-position="left"></el-divider>
            <div class="formItem-title">
              <span></span>
              <p>人员信息</p>

              <el-button style="margin-left: 16px; text-decoration: underline" v-if="flag.activeTab == 0" type="text"
                @click="openDriverDialog(1)">指派司机</el-button>

              <el-button style="margin-left: 16px; text-decoration: underline" type="text"
                @click="openDriverDialog(3, 'payee')">添加收款人</el-button>
            </div>

            <el-form-item v-if="flag.activeTab == 0" label="司机姓名" prop="DriverName">
              <el-input v-model="supplierForm.DriverName" disabled placeholder="请输入司机姓名" class="inputWidth"></el-input>
            </el-form-item>
            <el-form-item v-if="flag.activeTab == 0" label="联系电话" prop="DriverPhone" maxlength="11">
              <el-input v-model="supplierForm.DriverPhone" disabled onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入司机手机号码" class="inputWidth"></el-input>
            </el-form-item>

            <el-form-item label="收款人" prop="PayeeName">
              <el-input v-model="supplierForm.PayeeName" disabled placeholder="请输入收款人姓名" class="inputWidth"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="PayeePhone">
              <el-input v-model="supplierForm.PayeePhone" disabled maxlength="11"
                onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入收款人手机号码" class="inputWidth"></el-input>
            </el-form-item>

            <el-form-item label="提货人" prop="LoaderName">
              <el-input v-model="supplierForm.LoaderName" placeholder="请输入提货人姓名" class="inputWidth"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="LoaderPhone">
              <el-input v-model="supplierForm.LoaderPhone" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入提货人手机号码" class="inputWidth"></el-input>
            </el-form-item>

            <el-form-item label="收货人" prop="SignUserName">
              <el-input v-model="supplierForm.SignUserName" placeholder="请输入收货人姓名" class="inputWidth"
                maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="SignUserPhone">
              <el-input v-model="supplierForm.SignUserPhone" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入收货人手机号码" class="inputWidth"></el-input>
            </el-form-item>
          </div>

          <!-- 车辆信息 -->
          <div class="formItem" v-if="flag.activeTab == 0 && Object.keys(vehicleInfoObj).length != 0
            ">
            <el-divider content-position="left"></el-divider>
            <div class="formItem-title">
              <span></span>
              <p>车辆信息</p>
            </div>
            <el-form-item label="车牌">
              <el-input v-model="vehicleInfoObj.VehicleCarNumber" disabled></el-input>
            </el-form-item>
            <el-form-item label="车牌颜色">
              <el-input v-model="vehicleInfoObj.CarNumberColorName" disabled></el-input>
            </el-form-item>
            <el-form-item label="车辆所有人">
              <el-input v-model="vehicleInfoObj.VehicleMaster" disabled></el-input>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-input v-model="vehicleInfoObj.VehicleTypeName" disabled></el-input>
            </el-form-item>
            <el-form-item label="能源类型">
              <el-input v-model="vehicleInfoObj.VehiclePowerTypeName" disabled></el-input>
            </el-form-item>
            <el-form-item label="载重(吨)">
              <el-input v-model="vehicleInfoObj.VehicleLoad" disabled></el-input>
            </el-form-item>
            <el-form-item label="总重(吨)">
              <el-input v-model="vehicleInfoObj.VehicleLoadWeight" disabled></el-input>
            </el-form-item>
            <el-form-item label="车长(米)">
              <el-input v-model="vehicleInfoObj.VehicleLength" disabled></el-input>
            </el-form-item>
          </div>
        </el-form>
        <!-- 创建按钮 -->
        <div style="text-align: center; margin-top: 24px">
          <el-button type="primary" icon="el-icon-check" @click="comfirmCreate()">{{
            flag.isUpdata
            ? "修改订单"
            : flag.activeTab == 1
              ? "提交申请"
              : "创建货运计划"
          }}</el-button>
        </div>
      </div>

      <!-- 司机列表dialog -->
      <el-dialog :title="chooseDriverPage == 1
              ? '指派司机'
              : chooseDriverPage == 2
                ? '关联车辆'
                : '添加收款人'
            " :visible.sync="flag.driverListDialog" width="1200px" class="appointDriverAndCar"
        :close-on-click-modal="false">
        <AppointDriverAndCar @upChooseDriverPage="upChooseDriverPage" ref="appointDriverAndCar"></AppointDriverAndCar>
        <!-- 底部按钮 -->
        <span slot="footer">
          <el-button size="mini" type="primary" @click="flag.driverListDialog = false" style="margin-top: 15px"
            v-show="chooseDriverPage == 1 || chooseDriverPage == 3">取消</el-button>
          <el-button size="mini" type="primary" style="margin-top: 15px" v-show="chooseDriverPage == 2" @click="
            (chooseDriverPage = 1),
            ($refs.appointDriverAndCar.currentInfo = 1)
            ">上一页</el-button>
          <el-button size="mini" type="primary" @click="confirmPeople">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getDataDict, getArea, getAreaChildren } from "@/api/common/common";
import {
  createOrderHtw,
  GetHtwOrdersFirst,
  updataOrderHtw,
} from "@/api/contractLogistics/createPlan";
import { getSecPriseList } from "@/api/goodsOwner/serviceProviderCooperation/index.js";
import AppointDriverAndCar from "@/components/businessCmpt/appointDriverAndCar.vue";
export default {
  data() {
    return {
      flag: {
        activeTab: "1",
        driverListDialog: false, //司机列表dialog
        tipsTxtShow: false, // 帮助提示语
        isUpdata: false, // 是否是更改订单内容
        loading: false, // 加载中
      },
      supplierList: [], //服务商列表
      goodsUnitList: [], //货品单位列表
      supplierForm: {
        FacilitatorID: "", // 服务商id
        StartAddress: "", //起始地
        StartAdressRoad: "", //起始地详细地址
        UpAddress: "", // 装货地
        UpAdressRoad: "", // 装货地详细地址
        EndAddress: "", //到达地
        EndAddressRoad: "", //到达地详细地址
        UnAddress: "", //卸货地
        UnAdressRoad: "", // 卸货地详细地址
        LoadingTime: "", // 装卸时间
        LoadingStartTime: "", // 装货开始时间
        LoadingEndTime: "", // 装货结束时间
        SendTime: "", // 运输时间
        StartTime: "", //  运输开始时间
        EndTime: "", // 运输结束时间
        GoodsName: "", // 货品名称
        GoodsUnit: "", //货品单位
        LoadCount: "", //装货数量
        CarrierPrice: "", //运费
        GoodsValue: "", //货品价值
        GoodsTotalWeight: "", // 货品总重
        PayeeName: "", // 收款人姓名
        PayeeId: "", // 收款人id
        PayeePhone: "", // 收款人手机号
        LoaderName: "", // 提货人姓名
        LoaderPhone: "", // 提货人手机号
        SignUserName: "", // 收货人姓名
        SignUserPhone: "", // 收货人手机号
        DriverName: "", // 司机姓名
        DriverId: "", // 司机id
        DriverPhone: "", // 司机手机号
        CarNumber: "", // 车牌号
        // 三级联动数据 起始地
        StartPosition: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad: this.selectPosition,
        },
        // 三级联动数据 装货地
        UpAddressPosition: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          isShow: true,
          lazyLoad: this.selectPosition,
        },
        // 三级联动数据 到达地
        EndPosition: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          lazyLoad: this.selectPosition,
        },
        // 三级联动数据 卸货地
        UnAddressPosition: {
          area: [],
          value: "label",
          disabled: false,
          lazy: true,
          isShow: true,
          lazyLoad: this.selectPosition,
        },
      },
      supplierRule: supplierRule,
      currentSelectedDriver: {}, //当前勾选的司机
      currentSelectedVehicle: {}, //当前勾选的车辆
      chooseDriverPage: 1, // 选择司机还是选择车辆， 1 司机， 2车辆， 3 代收人
      vehicleInfoObj: {}, // 选择车辆后车辆信息
    };
  },
  components: {
    AppointDriverAndCar,
  },
  created() {
    this.getSecPriseList(); // 获取服务商列表
    this.getGoodsUnitList(); //获取物品单位

    // 物流计划跳转 => 创建计划, 取消计划, 重选服务商, 重选司机
    const { type, orderId, reselect, upDriver } = this.$route.query;
    // type 跳转tab页
    // reselect 如果为true表示需要重新选中服务商, 不需要v-model
    if (type) {
      this.flag.activeTab = type;

      if (!orderId) return; // 重选服务商, 重选司机 往下走
      this.flag.isUpdata = true; // 修改订单提交文字
      this.flag.loading = true;
      this.getOrderDetail(orderId, reselect, upDriver);
    }
  },
  methods: {
    // 省市区联动
    async selectPosition(node, resolve) {
      const { level } = node;
      if (level == 0) {
        this.flag.loading = true;

        await getArea()
          .then((res) => {
            res.result[0].forEach((item) => {
              item.label = item.fullname;
              item.leaf = level >= 2;
            });
            resolve(res.result[0]);
          })
          .finally(() => (this.flag.loading = false));
      } else {
        //如果是香港和澳门特别行政区就特别处理
        if (node.data.id == "810000" || node.data.id == "820000") {
          await getAreaChildren({ id: node.data.id }).then((res) => {
            res.result[0].forEach((item) => {
              item.label = item.fullname;
              item.leaf = true;
            });
            resolve(res.result[0]);
          });
        } else {
          await getAreaChildren({ id: node.data.id }).then((res) => {
            res.result[0].forEach((item) => {
              item.label = item.fullname;
              item.leaf = level >= 2;
            });
            resolve(res.result[0]);
          });
        }
      }
    },

    // 获取服务商列表
    getSecPriseList() {
      let data = {
        EnterpriseFullName: "",
        InvoiceType: "",
        StartDatetime: "",
        EndDatetime: "",
        pageSize: 2147483647,
        pageIndex: 1,
        LegalPersonlPhone: "",
      };
      getSecPriseList({ Json: JSON.stringify(data) }).then((res) => {
        this.supplierList = res.equipments;
      });
    },

    //获取物品单位
    getGoodsUnitList() {
      getDataDict({ type: 28 }).then((res) => {
        this.goodsUnitList = res.patterSetInfo;
      });
    },

    // 选择装货时间
    loadingTime(e) {
      this.supplierForm.LoadingStartTime = e[0];
      this.supplierForm.LoadingEndTime = e[1];
    },

    // 选择运输时间
    sendTime(e) {
      this.supplierForm.StartTime = e[0];
      this.supplierForm.EndTime = e[1];
    },

    changeTabs(e) {
      // 清除表单内容
      if (this.$refs["supplierForm"] != undefined) {
        this.$refs["supplierForm"].resetFields();
      }
      this.flag.isUpdata = false;
      this.clearDialogInfo();
    },

    // 清除弹框数据
    clearDialogInfo() {
      if (this.$refs.appointDriverAndCar) {
        this.$refs.appointDriverAndCar.initialization();
      }
    },

    // 同步地址
    unifyAddress(num) {
      let {
        StartPosition: { area: startArea = [] },
        StartAdressRoad,
        EndPosition: { area: EndArea = [] },
        EndAddressRoad,
      } = this.supplierForm || {};

      if (num == 1) {
        if (startArea.length) {
          this.supplierForm.UpAddressPosition.isShow = false;
          this.$refs[
            "UpAddressPosition"
          ].inputValue = `${startArea[0]} / ${startArea[1]} / ${startArea[2]}`;
          this.supplierForm.UpAddressPosition.area = startArea;
          this.$refs["supplierForm"].clearValidate(["UpAddressPosition.area"]);
        }
        StartAdressRoad && (this.supplierForm.UpAdressRoad = StartAdressRoad);
      } else {
        if (EndArea.length) {
          this.supplierForm.UnAddressPosition.isShow = false;
          this.$refs[
            "UnAddressPosition"
          ].inputValue = `${EndArea[0]} / ${EndArea[1]} / ${EndArea[2]}`;
          this.supplierForm.UnAddressPosition.area = EndArea;
          this.$refs["supplierForm"].clearValidate(["UnAddressPosition.area"]);
        }
        EndAddressRoad && (this.supplierForm.UnAdressRoad = EndAddressRoad);
      }

      this.$nextTick(() => {
        this.supplierForm.UpAddressPosition.isShow = true;
        this.supplierForm.UnAddressPosition.isShow = true;
      });
    },

    //创建订单
    comfirmCreate() {
      this.$refs["supplierForm"].validate((valid) => {
        if (!valid) return;

        if (
          !+this.supplierForm.LoadCount ||
          !+this.supplierForm.GoodsTotalWeight ||
          !+this.supplierForm.CarrierPrice ||
          !+this.supplierForm.GoodsValue
        ) {
          this.$message.info("货物信息相关内容不能≤0!!");
          return;
        }

        const { isUpdata } = this.flag;
        const loading = this.$loading({
          lock: true,
          text: `${isUpdata ? "修改" : "创建"}订单中...`,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        this.supplierForm.StartAddress = this.supplierForm.StartPosition.area;
        this.supplierForm.EndAddress = this.supplierForm.EndPosition.area;
        this.supplierForm.UpAddress = this.supplierForm.UpAddressPosition.area;
        this.supplierForm.UnAddress = this.supplierForm.UnAddressPosition.area;
        this.supplierForm.htWLOrder = this.flag.activeTab;

        let ajaxFn = isUpdata ? updataOrderHtw : createOrderHtw;
        ajaxFn(this.supplierForm)
          .then((res) => {
            this.$message.success(`订单${isUpdata ? "修改" : "创建"}成功`);
            this.$router.push({
              path: "/contractLogistics/contractWaybill/index",
              query: {
                type: this.flag.activeTab,
              },
            });
          })
          .catch((err) => { })
          .finally(() => {
            loading.close();
          });
      });
    },

    // 选择司机还是选择车辆
    upChooseDriverPage(data) {
      this.chooseDriverPage = data;
    },

    // 打开指派司机/车辆dialog
    openDriverDialog(status, type) {
      if (this.chooseDriverPage != status) {
        this.clearDialogInfo();
      }

      this.chooseDriverPage = status;
      this.flag.driverListDialog = true;
      this.$nextTick(() => {
        this.$refs.appointDriverAndCar.currentInfo = type ? 3 : 1; // 添加收款人
        this.$refs.appointDriverAndCar.getDriverList();
      });
    },

    // 确认司机 或者 收款人， 赋值给输入框
    confirmPeople() {
      let { driverInfo, vehicleInfo } = this.$refs.appointDriverAndCar;

      if (Object.keys(driverInfo).length != 0) {
        if (this.chooseDriverPage == 3) {
          // 赋值收款人
          this.supplierForm.PayeeName = driverInfo.DriverName;
          this.supplierForm.PayeeId = driverInfo.UserID;
          this.supplierForm.PayeePhone = driverInfo.TelPhone;
          this.supplierForm.PayeeIDCard = driverInfo.DriverIDCard
        } else {
          // 赋值司机
          this.supplierForm.DriverName = driverInfo.DriverName;
          this.supplierForm.DriverId = driverInfo.UserID;
          this.supplierForm.DriverPhone = driverInfo.TelPhone;
          this.supplierForm.DriverIDCard = driverInfo.DriverIDCard
          this.supplierForm.CarNumber = "";
        }
      }

      // 赋值车牌车牌
      if (Object.keys(vehicleInfo).length != 0) {
        this.supplierForm.CarNumber = vehicleInfo.CarNumber;
        this.vehicleInfoObj = vehicleInfo;
      }

      this.flag.driverListDialog = false;
    },

    // 获取订单详情, 回显数据
    getOrderDetail(orderID, reselect, upDriver) {
      GetHtwOrdersFirst(orderID)
        .then((res) => {
          this.supplierForm = {
            ...this.supplierForm,
            ...res.data,
            LoadingTime: [res.data.LoadingStartTime, res.data.LoadingEndTime],
            SendTime: [res.data.StartTime, res.data.EndTime],
          };

          this.$refs[
            "StartPosition"
          ].inputValue = `${res.data.StartAddress[0]} / ${res.data.StartAddress[1]} / ${res.data.StartAddress[2]}`;
          this.$refs[
            "EndPosition"
          ].inputValue = `${res.data.EndAddress[0]} / ${res.data.EndAddress[1]} / ${res.data.EndAddress[2]}`;

          this.$refs[
            "UpAddressPosition"
          ].inputValue = `${res.data.UpAddress[0]} / ${res.data.UpAddress[1]} / ${res.data.UpAddress[2]}`;
          this.$refs[
            "UnAddressPosition"
          ].inputValue = `${res.data.UnAddress[0]} / ${res.data.UnAddress[1]} / ${res.data.UnAddress[2]}`;

          this.supplierForm.StartPosition.area = res.data.StartAddress;
          this.supplierForm.EndPosition.area = res.data.EndAddress;
          this.supplierForm.UpAddressPosition.area = res.data.UpAddress;
          this.supplierForm.UnAddressPosition.area = res.data.UnAddress;

          if (reselect == "true") this.supplierForm.FacilitatorID = ""; // 清空服务商,重新选择服务商
          if (upDriver == "true") {
            // 清空司机,重新选择司机
            this.supplierForm.DriverId = "";
            this.supplierForm.DriverName = "";
            this.supplierForm.DriverPhone = "";
            this.supplierForm.CarNumber = "";
          }
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },
  },
  watch: {
    //解决签收时间清空后报错的bug
    "supplierForm.LoadingTime"(val) {
      if (!val) {
        this.searchForm.time = ["", ""];
      }
    },
    "supplierForm.SendTime"(val) {
      if (!val) {
        this.searchForm.time = ["", ""];
      }
    },
  },
};

const supplierRule = {
  FacilitatorID: [
    { required: true, message: "请选择服务商", trigger: "change" },
  ],
  "StartPosition.area": [
    { required: true, message: "请选择起始地", trigger: "change" },
  ],
  StartAdressRoad: [
    {
      required: true,
      message: "请输入起始地街道、门牌号具体地址",
      trigger: "change",
    },
  ],
  "UpAddressPosition.area": [
    { required: true, message: "请选择提货地点", trigger: ["blur", "change"] },
  ],
  UpAdressRoad: [
    {
      required: true,
      message: "请输入提货地点街道、门牌号具体地址",
      trigger: "change",
    },
  ],
  "EndPosition.area": [
    { required: true, message: "请选择到达地", trigger: "change" },
  ],
  EndAddressRoad: [
    {
      required: true,
      message: "请输入到达地街道、门牌号具体地址",
      trigger: "change",
    },
  ],
  "UnAddressPosition.area": [
    { required: true, message: "请选择卸货地点", trigger: ["blur", "change"] },
  ],
  UnAdressRoad: [
    {
      required: true,
      message: "请输入卸货地点街道、门牌号具体地址",
      trigger: "change",
    },
  ],
  LoadingTime: [
    {
      required: true,
      message: "请选择装货起始时间--装货结束时间",
      trigger: "change",
    },
  ],
  SendTime: [
    {
      required: true,
      message: "请选择运输起始时间--运输到达时间",
      trigger: "change",
    },
  ],
  GoodsName: [{ required: true, message: "请输入货品名称", trigger: "change" }],
  GoodsUnit: [{ required: true, message: "请选择货品单位", trigger: "change" }],
  LoadCount: [
    { required: true, message: "请输入货品总数量", trigger: "change" },
  ],
  CarrierPrice: [{ required: true, message: "请输入运费", trigger: "change" }],
  UnitPrice: [
    {
      required: true,
      message: "请输入运输数量所对应的运输单价",
      trigger: "change",
    },
  ],
  GoodsValue: [
    { required: true, message: "请输入货品总价值", trigger: "change" },
  ],
  GoodsTotalWeight: [
    { required: true, message: "请输入货品总重量", trigger: "change" },
  ],
  DriverName: [
    { required: true, message: "请输入司机姓名", trigger: "change" },
  ],
  DriverPhone: [
    { required: true, message: "请输入司机手机号码", trigger: "change" },
  ],
  PayeeName: [
    { required: true, message: "请输入收款人姓名", trigger: "change" },
  ],
  PayeePhone: [
    {
      required: true,
      message: "请输入收款人手机号码",
      trigger: "change",
    },
  ],
  LoaderName: [
    { required: true, message: "请输入提货人姓名", trigger: "change" },
  ],
  LoaderPhone: [
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      required: true,
      message: "请输入提货人手机号码",
      trigger: "change",
    },
  ],
  SignUserName: [
    { required: true, message: "请输入收货人姓名", trigger: "change" },
  ],
  SignUserPhone: [
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      required: true,
      message: "请输入收货人手机号码",
      trigger: "change",
    },
  ],
};
</script>

<style lang="scss" scoped>
.createPlanPage .el-table__header-wrapper .el-checkbox {
  display: none;
}

.el-divider--horizontal {
  margin: 0 0 10px 0 !important;
}

.facilityBox {
  position: relative;

  .tips {
    color: #0f5fff;
    position: absolute;
    top: 17px;
    right: 16px;
    font-size: 14px;
    cursor: pointer;

    span {
      margin-left: 3px;
    }

    .tipsTxt {
      width: 492px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      padding: 16px;
      box-sizing: border-box;
      position: absolute;
      top: -15px;
      left: -500px;
      z-index: 99;

      p {
        line-height: 18px;
        font-weight: bold;
      }

      .triangle {
        width: 0;
        height: 0;
        border-width: 5px 0px 5px 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f5f5f5;
        position: absolute;
        right: -5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .formItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .formItem-title {
      width: 100%;
      display: flex;
      align-items: center;
      color: #0f5fff;

      span {
        display: block;
        width: 1px;
        height: 12px;
        background: #0f5fff;
        border-radius: 12px 12px 12px 12px;
        margin-right: 4px;
      }
    }

    .el-form-item {
      margin-right: 30px;
    }

    .formItem-btn {
      width: 40px;
      height: 40px;
      margin-top: 15px;
      margin-right: 30px;
    }
  }

  /deep/.el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    width: 355px !important;
  }

  .el-form--inline .el-form-item {
    width: 20% !important;
    margin: 0 24px 22px !important;
  }

  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .appointDriverAndCar {
    /deep/.el-dialog__body {
      padding: 6px 20px !important;
    }
  }
}

.createPlanPage .inputWidth {
  width: 355px !important;
}
</style>

<style>
.appointDriverAndCar .el-dialog {
  top: -10%;
}
</style>
